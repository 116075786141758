import teaserclick from '@core/scripts/components/tracking/landmarks/teaserclick';
import Logger from '@core/scripts/components/logger';
import meta from '@core/scripts/helper/meta';
import sendBeacon from '@core/scripts/helper/send-beacon.js';
import JshModule from '@core/scripts/helper/jsh-module';
import {implicitLandmarkData} from "./implicitLandmarkData.js";

function initLandmarkModules() {

    /**
     * As the new implementation of jsh-module does not support multiple instances of the same module on the same page,
     * we do it our own way with a do-while loop.
     * The loop will run as long as there are still modules on the page that are not initialized yet.
     */
    do {
        const {moduleScope} = JshModule('teaser/landmark');

        const LANDMARK_CONTROLLER_URL = meta.value('landmark:url');

        function init() {

            const all_links = moduleScope.querySelectorAll('a');
            if (all_links == null) {
                return;
            }
            all_links.forEach((node) => {
                const closest_element = node.closest('[jsh-landmark__teaserclick]');
                if (!closest_element) {
                    return;
                }
                let landmarkConfig = closest_element.getAttribute(`jsh-landmark__teaserclick`);
                let landmarkConfigParent = closest_element.getAttribute(`jsh-landmark__parent`);
                initializeLink(node, landmarkConfig, landmarkConfigParent);
            });
        }

        function initializeLink(element, landmarkConfig, landmarkConfigParent) {
            let parsedLandmarkConfig = {
                teaserclick: landmarkConfig,
                id: landmarkConfig,
                ...(landmarkConfigParent && {teaser_id: landmarkConfigParent}),
            };
            teaserclick(element, parsedLandmarkConfig, sendClickLandmark);
        }

        function sendClickLandmark(value) {
            const landmarkName = 'teaserclick';
            const implicitLandmarkDataHolder = implicitLandmarkData();
            const teaserPageLandmarks = getTeaserPageLandmarks();
            const landmarkData = Object.assign({}, implicitLandmarkDataHolder, teaserPageLandmarks, value);

            if (!sendBeacon(`${LANDMARK_CONTROLLER_URL}${landmarkName}/`, landmarkData)) {
                Logger.error(`Could not send landmark!\n\tReason: Unknown (beacon)\n\tLandmark name: ${landmarkName}`);
            } else {
                Logger.debug(`Landmark sent: `, landmarkData);
            }
        }

        function getTeaserPageLandmarks() {
            const contentList = document.getElementsByClassName('content-list');
            return {
                cfEnvironment: contentList[0].getAttribute(`jsh-landmark__cf-environment`),
                teaserPageEntryId: contentList[0].getAttribute(`jsh-landmark__teaser-page-entry-id`),
            };
        }

        init();

    } while (document.querySelector('[jsh-module="teaser/landmark"]') !== null);
}

window.addEventListener('DOMContentLoaded', initLandmarkModules);

